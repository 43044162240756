
.details-container {
    .movie_card {
        button {
            border: 1px solid $color-1;
            text-align: center;
            border-radius: 10px;
            padding: 5px;
            margin: 0 auto;
            margin-bottom: 5px;
            height: 30px;
            cursor: pointer;
            margin-left: 25px;
            &:hover {
                background-color: $color-1;
                color: white;
            }
        }
    }
}


