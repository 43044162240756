.container-fluid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}


.tm-mt-90 {
    margin-top: 30px;
}
.tm-mb-74 {
    margin-bottom: 74px;
}


.row {
    display: flex;
    flex-wrap: wrap;
}


.col-lg-5,
.col-md-6,
.col-12 {
    padding: 15px;
}

.tm-about-img-text {
    text-align: justify;

    h2 {
        font-size: 50px;
        color: $color-1;
        text-transform: uppercase;
        margin-left: -45px;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;

        b {
            font-weight: bold;
            color: $color-1;
        }
    }
}


img.img-fluid1 {
    max-width: 100%;
    height: auto;
    margin-top: 45px;
}



