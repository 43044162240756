.mb-5 {
  margin-bottom: 3rem!important;
}

figure.effect-ming {
  background: #030c17;
  h2 {
      font-size: 1.3em;
      opacity: 0;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
  }
}

.tm-movix-item {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.tm-movix-item img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.tm-movix-item figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.tm-movix-item figcaption::before,
.tm-movix-item figcaption::after {
  pointer-events: none;
}

.tm-movix-item figcaption,
.tm-movix-item figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tm-movix-item figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.tm-movix-item h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  margin: 0;
}

.tm-gallery div.d-block {
  animation: show .5s ease;
}

@keyframes show {
  0% {
      opacity: 0;
      transform: scale(0.9);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}

figure.effect-ming {
  background: #030c17;
}

figure.effect-ming img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4,1.4,1);
  transform: scale3d(1.4,1.4,1);
}

figure.effect-ming h2 {
  font-size: 1.3em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover h2 {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

figure.effect-ming:hover figcaption {
  background-color: rgba(58,52,42,0);
}

figure.effect-ming:hover img {
  opacity: 0.4;
}

@media (max-width: 414px) {

  .mb-5 {
    
    margin-top: -10%;
}
}

