.categoriesMovies {

  .tm-mt-60 {
    margin-top: 60px;
  }

  .container-fluid {
    max-width: 95%;
  }




  .categoriesMovies-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 45px;
    margin-right: -145px;

  }

  .placeholder-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 8px;
    font-family: sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #555;

    margin-top: 360px;

    h2 {

      margin-top: 0px;
      margin-bottom: 28.5rem;
      font-weight: 500;
      line-height: 1.2;
      color: $color-1;
      animation: fadeInOut 2s infinite;
      margin-right: 150px
    }

    @keyframes fadeInOut {

      0%,
      100% {
        opacity: 0;
        color: $color-1;
      }

      25% {
        color: $color-1;
      }

      50% {
        opacity: 1;
        color: black;
      }

      75% {
        color: $color-1;
      }
    }
  }

  select {
    width: 260px;
    margin-left: 78rem;
    border: 1px solid rgb(45, 22, 45);
    background-color: $color-4;
    color: $color-1;
    height: 30px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;


    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $color-1;

    }

    option {
      color: $color-1;

      &:hover,
      &:focus,
      &:active,
      &:checked {
        background-color: $color-1;
        color: #fff;

      }
    }


  }

  .titleCategory {
    text-align: center;

  }
}

.button-container1 {
  display: flex;
  justify-content: space-between;


}

.prev-button,
.next-button {
  background-color: $color-1;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;




  .prev- &:hover {
    background-color: $color-1;
    color: black;
  }

  &:disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
  }
}
@media (max-width: 820px) {

  .categoriesMovies select {
    
    margin-left: 17rem;
    
}

  .categoriesMovies .placeholder-container h2 {
    
    margin-right: 0px;
}

}

@media (max-width: 414px) {
  .categoriesMovies select {
    
    margin-left: 5rem;
    
  }

  .categoriesMovies .placeholder-container h2 {
    
    text-align: center;
    margin-right: auto;
  }

  


}