.tm-bg-gray {
    background-color: $color-4;
}

.tm-text-gray {
    color: $color-2;
}



.tm-text-primary {
    color: $color-2;
}

.tm-footer {
    font-size: 0.95rem;
    padding-left: 150px;
    padding-right: 150px;

    .tm-footer-title {
        font-size: 1.4rem;
        color: $color-1;
    }

     .tm-footer-links {
        list-style: none;
        padding-left: 0;
        

        li {
            margin-bottom: 5px;

            a {
                color: $color-2;
                transition: color 0.3s;

                &:hover {
                    color: $color-1;
                }
            }
        }
    }

    .tm-social-links {
        list-style: none;
        padding-left: 0;

        li {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: inline-block;
                width: 50px;
                height: 50px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}


@media (max-width: 820px) {
    
    .tm-footer {
        font-size: 0.95rem;
        padding-left: 0;
        padding-right: 0;

        .p-footer {
            margin-right: 50px;
        }
    }
    .social-m {
        margin-left: -138px;
    }

}

@media (max-width: 414px) {
    
    .tm-footer {
        font-size: 0.95rem;
        padding-left: 20px;
        padding-right: 20px;
    }
    .social-m {
        margin-left: -138px;
    }

}