@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
$font-1: "Poppins", sans-serif;
$color-1: #CBA328;
$color-2: #f7f7f7;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$border-color: #F2DA96;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0.4rem auto;

    font-family: $font-1;
    color: $color-3;
    background-color: $color-2;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $color-3;
    cursor: pointer;
}

a:visited {
    color: $color-3;
}