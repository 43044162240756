.notFound {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    height:100vh; 

    h1 {
        margin-bottom: 20px;
        font-size: 50px;
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
        border-radius: 40px;
    }
}
