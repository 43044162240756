.tm-mb-90 {
  margin-bottom: 90px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.prev-button, .next-button {
  background-color: $color-1;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;

  &:hover {
    background-color: $color-1;
    color: black;
  }

  &:disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
  }
}
 


 


