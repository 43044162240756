@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
        color: $color-1;  
    }

    25% {
        color: $color-1;  
    }

    50% {
        opacity: 1;
        color: black;  
    }

    75% {
        color: $color-1;  
    }
}

.searchMovie {
    .tm-mt-60 {
        margin-top: 60px;
    }
    
    .container-fluid {
        max-width: 1770px;
    }


    .input {
        display: flex;
        align-items: center;
        width: 20;
        border-radius: 5px;
        border: 3px solid $color-1;
        font: inherit;
        padding-left: 0.5rem;
        height: 40px;
        margin-right: 320px;
    }

    .input-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2%;
    }

    .placeholder-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-top: 10%;

        h2 {
            margin-bottom: 250px;
            animation: fadeInOut 2s infinite;
        }
    }
}
.button-container2 {
    display: flex;
    justify-content: space-between;
  }
  
  .prev-button, .next-button {
    background-color: $color-1;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
  
    &:hover {
      background-color: $color-1;
      color: black;
    }
  
    &:disabled {
      background-color: #ccc;
      color: #888;
      cursor: not-allowed;
    }
  }
  @media (max-width: 575px) {
    
    .searchMovie .input {
        display: flex;
        align-items: center;
        width: 20;
        border-radius: 5px;
        border: 3px solid #CBA328;
        font: inherit;
        padding-left: 0.5rem;
        height: 40px;
        margin-right: 100px;
    }
    

    
}

@media (max-width: 400px) {
    .searchMovie .input {
        display: flex;
        align-items: center;
        width: 20;
        border-radius: 5px;
        border: 3px solid #CBA328;
        font: inherit;
        padding-left: 0.5rem;
        height: 40px;
        margin-right: 100px;
    }
    
}


@media (max-width: 414px) {


.searchMovie .placeholder-container h2 {
    margin-bottom: 250px;
    animation: fadeInOut 2s infinite;
    text-align: center;
}


}
  