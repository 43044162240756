
.popular {
    .tm-mt-60 {
        margin-top: 60px;
    }

    .container-fluid {
        max-width: 1770px;
    }
    .tm-text-primary {
        color: $color-1;
        margin-bottom: -45px;
    }

}

@media (max-width: 414px) {

    .col-6 {
        flex: 0 0 auto;
        width: 78%;
    }
}

