.details-container {
	flex: 1;
	flex-direction: column;
    

	.textActor {
		text-align: left;
		font-weight: bold;
		color: $color-1;
	}
  }
  
  .movie_card {
    max-width: 1260px;
    width: 100%;
    margin:  auto;
    min-height: 500px ;
margin-top: 100px;
margin-bottom: 150px;
    height: auto;
  
	.info_section {
	  position: relative;
	  width: 100%;
	  z-index: 2;
	  border-radius: 10px;
	  .movie_header {
		position: relative;
		padding: 25px;
		height: 40%;
		h1 {
		  color: $color-1;
		  font-weight: 400;
		}
		h4 {
		  color: #555;
		  font-weight: 400;
		}
		.minutes {
		  display: inline-block;
		  margin-top: 15px;
		  color: #555;
		  padding: 5px;
		  border-radius: 5px;
		  border: 1px solid rgba(0, 0, 0, 0.05);
		}
		.type {
		  display: inline-block;
		  color: $color-3;
		  margin-left: 10px;
		  cursor: pointer;
		  &:hover {
			text-decoration: underline;
		  }
		}
		.locandina {
		  position: relative;
          max-width:360px;
         margin-bottom: 100px;
		  float: left;
		  margin-right: 30px;
		  
		 
		  
		}
	  }
	  .movie_desc {
		padding: 25px;
		height: 50%;
		width: 100%;
		.text {
		  color: #545454;
		  margin-left: 155px;
		  margin-top: -35px;
		}
	  }
	  .actors_desc {
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
	  }
	}
	.blur_back {
	  position: absolute;
	  top: 0;
	  z-index: 1;
	  height: 100%;
	  right: 0;
	  background-size: cover;
	  border-radius: 11px;
	}
  }

  @media screen and (max-width: 820px) {
	.movie_card .info_section .actors_desc {
		padding-left: 30px;
		
	}

	.details-container .textActor {
		
		margin-left: 20px;
	}

}
 
  
  @media screen and (min-width: 768px) {
	.movie_header {
	  width: 65%;
	}
  
	.movie_desc {
	  width: 50%;
	 
	}
  
	
  
	.blur_back {
	  width: 80%;
	  background-position: -100% 10% !important;
	}
  }
  .movie_card .info_section .movie_desc .text {
    
   margin-left: 0;
   
}


 
  @media screen and (max-width: 768px) {
	.movie_card {
	  width: 100%;
	  margin: 70px auto;
	  min-height: 350px;
	  height: auto;
	}
  
	.blur_back {
	  width: 100%;
	  background-position: 50% 50% !important;
	}
  
	.movie_header {
	  width: 100%;
	  margin-top: 85px;
	}
  
	.movie_desc {
	  width: 100%;
	}
  
	.info_section {
	 
	  display: inline-grid;
	}

	.movie_card .info_section .actors_desc {
		padding: 74px;
		display: flex;
		flex-wrap: wrap;
		
	}
  }
  .text_actor {
	text-align: center;
	font-size: 25px;
  }
  
  @media screen and (max-width: 420px) {
	.details-container .textActor {
    
		margin-left: 23px;
	}
	}

  

	