.tm-search-input {
    width: 360px;
    border-radius: 0;
    padding: 12px 15px;
    color: $color-1; 
    border: none;

    &:focus {
        border-color: $color-1;
        box-shadow: 0 0 0 0.25rem rgba($color-1, 0.25); 
    }

    &::placeholder {
        color: $color-1;
        opacity: 1;
    }

    &::-ms-input-placeholder {
        color: $color-1;
    }
}

.tm-search-btn {
    color: $color-2; 
    background-color: $color-1;
    border: none;
    width: 100px;
    height: 50px;
    margin-left: -1px;
    border-radius: 0;
}
.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: $color-3;
    border-color: $color-3
}

@media (max-width: 767px) {
    .tm-search-input {
        width: 100%;
        max-width: 360px;
    }
    
}

@media (max-width: 575px) {
    

    .tm-search-input {
        width: 100%;
        max-width: 360px;
    }

    
}

@media (max-width: 400px) {
    .tm-search-input {
        width: 100%;
        max-width: 360px;
    }
}



