


.navigation-container {
  .App { background: $color-4;
    .container {
     

      header {
          .navigation {
              margin:  0;
              display: flex;
              a {
              margin: 10px;
              padding: 10px;
              position: relative;
              color: $color-2;
              }
              .active {
              &::after {
              content: "";
              width: 80%;
              height: 6px;
              background: $color-1;
              position: absolute;
              bottom: 1px;
              left: 50%;
              transform: translateX(-50%);
              }
              }
              }
      }
    }
  }
}